import {Typography} from "@mui/material";
import Box from "@mui/joy/Box";
import * as React from "react";
import {Link} from "react-router-dom";
import {useGetStorageBatteryQuery} from "../../shared/api/api";

const Battery = () => {

    const {data} = useGetStorageBatteryQuery();

    const titles = [
        {title: 'dacha', name: 'Dacha', path: '/battery/dacha'},
        {title: 'dementor', name: 'Dementor', path: '/battery/dementor'},
        {title: 'torec', name: 'Torec', path: '/battery/torec'},
        {title: 'luka_1', name: 'Luka 1', path: '/battery/luka1'},
        {title: 'luka_2', name: 'Luka 2', path: '/battery/luka2'}
    ];

    const getBatteryNumber = (title) => {
        const battery = data?.find(item => item.position === title);
        return battery ? battery.number_battery : 'N/A'; // Повертаємо номер батареї або "N/A", якщо не знайдено
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 2,
                    mb: 4,
                    textDecoration: 'underline'
                }}>
                <Typography variant="h6" component="h1">Battery replacement</Typography>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    gap: '16px',
                }}
            >
                {titles.map((titleItem, index) => (
                    <Box
                        key={index}
                        sx={{
                            flex: '1 1 calc(50% - 16px)',
                            maxWidth: 'calc(50% - 16px)',
                            '@media (min-width: 768px)': {
                                flex: '1 1 calc(33.33% - 16px)',
                                maxWidth: 'calc(33.33% - 16px)',
                            },
                        }}
                    >
                        <Link
                            to={titleItem.path}
                            state={{
                                batteryNumber: getBatteryNumber(titleItem.title),
                                position: titleItem.title,
                                namePost: titleItem.name
                            }}
                            style={{ textDecoration: 'none' }}
                        >
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '8px',
                                    padding: '16px',
                                    textAlign: 'center',
                                    background: 'linear-gradient(135deg, #32a852 0%, #a8e063 100%)',
                                    minHeight: 100,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#fff',
                                    position: 'relative',
                                }}
                            >
                                {/* Номер батареї, позиціонований у правому верхньому куті */}
                                <Box
                                    component={'span'}
                                    sx={{
                                        position: 'absolute',
                                        top: 8,
                                        right: 8,
                                        backgroundColor: '#000',
                                        color: '#fff',
                                        borderRadius: '4px',
                                        padding: '4px 8px',
                                        fontSize: '14px',
                                    }}
                                >
                                    {getBatteryNumber(titleItem.title)}
                                </Box>

                                {/* Назва блоку */}
                                <Typography>{titleItem.name}</Typography>
                            </Box>
                        </Link>
                    </Box>
                ))}
            </Box>
        </>
    );
};

export default Battery;
