import {useGetStorageBatteryQuery} from "../../shared/api/api";
import * as React from 'react';
import Table from '@mui/joy/Table';
import Box from '@mui/joy/Box';
import {Typography} from "@mui/material"; // Використаємо Box для центрованої таблиці

const StorageBattery = () => {
    const {data, error, isLoading} = useGetStorageBatteryQuery();

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;


    const filteredData = data?.filter(row => row.position === 'storage');


    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt:2,
                    mb:2,
                    textDecoration: 'underline'
                }}>
                <Typography variant="h6" component="h1">Storage Battery</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Table
                    hoverRow
                    sx={{
                        width: '80%',
                        margin: 'auto',
                        '@media (max-width: 600px)': {
                            width: '100%',
                        },
                    }}
                >
                    <thead>
                    <tr>
                        <th style={{textAlign: 'center'}}>Number Battery</th>
                        <th style={{textAlign: 'center'}}>Position</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredData?.map((row) => (
                        <tr key={row.id}>
                            <td style={{textAlign: 'center'}}>{row.number_battery}</td>
                            <td style={{textAlign: 'center'}}>{row.position}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Box>
        </>
    );
};

export default StorageBattery;
